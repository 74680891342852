




























import { Component, Vue } from 'vue-property-decorator';
import { Collaborateur } from '@/models';
import { CollaborateursContent } from '@/components';
import { collaborateursAffiliation, contextEmployeur, taches, toast } from '@/store';
import { ERRORTYPE, NavigationHelper } from '@projetlucie/lc-front-sdk-old';
import { COLLABORATEURS_TYPE, ONBOARDINGTACHESTATEDTO } from '@/enums';
import { pagePath } from '@/utils';
import { CollaborateurService } from '@/services';

@Component({
  components: { CollaborateursContent }
})
export default class OnboardingAncienContrat extends Vue {
  private aucunCollaborateur = false;

  mounted() {
    this.addRepresentantLegal();
  }

  public addRepresentantLegal() {
    if (this.collaborateurs.length == 0) {
      const employeur = this.employeur;
      if (employeur) {
        const representantLegal: Collaborateur = {
          npp: employeur.numeroPersonne,
          prenom: employeur.prenom ?? '',
          nom: employeur.nom ?? '',
          email: employeur.email ?? '',
          telephone: employeur.telMobile ?? '',
          type: COLLABORATEURS_TYPE.ACTIF,
          dateFinSituation: '',
          dateDebutSituation: '',
          hasFailed: false,
          isSelected: true
        };

        collaborateursAffiliation.addCollaborator(representantLegal);

        toast.createAndPushToast(
          this.$t('onboarding.collaborateurs.ajoutAutomatique.title').toString(),
          this.$t('onboarding.collaborateurs.ajoutAutomatique.subtitle').toString(),
          true,
          ERRORTYPE.DEFAULT
        );
      }
    }
  }

  get collaborateurs() {
    return collaborateursAffiliation.getCollaboratorList;
  }

  get getContractInfo() {
    return contextEmployeur.getEmployeur?.contrat;
  }

  get employeur() {
    return contextEmployeur.getEmployeur;
  }

  get referenceTache() {
    return taches.currentTache;
  }

  public async valid() {
    if (this.collaborateurs.some((c: Collaborateur) => c.hasFailed) || !this.employeur) {
      toast.createAndPushToast(
        this.$t('onboarding.collaborateurs.error.title').toString(),
        this.$t('onboarding.collaborateurs.error.subtitle').toString(),
        true,
        ERRORTYPE.DANGER
      );
      return;
    }

    // Tableau des collabs rempli
    if (this.collaborateurs.length && !this.aucunCollaborateur) {
      const numeroContrat = this.getContractInfo?.numeroContrat;
      const versionContrat = this.getContractInfo?.versionContrat;
      const numeroPersonneMorale = this.employeur.numeroPersonneMorale;
      if (numeroContrat && numeroPersonneMorale && versionContrat != null) {
        const response = await CollaborateurService.postCollaboratorsList(
          this.collaborateurs,
          numeroPersonneMorale,
          numeroContrat,
          versionContrat
        );

        if (response) {
          await this.validTaskAndGoHome(this.$t('onboarding.collaborateurs.success.title').toString());
        }
      }
    }
    // Case Aucun salarié cochée et si données employeurs
    else if (
      this.aucunCollaborateur &&
      this.employeur.contrat &&
      this.employeur.contrat.numeroContrat &&
      this.employeur.contrat.versionContrat !== null
    ) {
      const response = await CollaborateurService.updateContractWithNoEmployees(
        this.employeur.contrat.numeroContrat,
        this.employeur.contrat.versionContrat
      );
      if (response) {
        await this.validTaskAndGoHome(this.$t('common.saved').toString());
      }
    }
  }

  public async validTaskAndGoHome(message: string) {
    if (this.referenceTache) {
      await taches.updateTache({ tache: this.referenceTache, statut: ONBOARDINGTACHESTATEDTO.FINISH });
    }
    toast.createAndPushToast(message, '', true, ERRORTYPE.DANGER);
    this.$router.push(pagePath.VueEnsemble);
  }

  public goBack() {
    NavigationHelper.goBack(this.$router);
  }
}
